import { useMemo } from "react";
import type { ViewLegsForDriverResponse } from "../../../new/api/JobApi";
import {
	legStatusTypeNames,
	legTypeNames,
	toDatetimeString,
} from "../../../new/helpers";

export const DriverLegItemTitle = ({
	collectionDate,
	deliveryDate,
	customerName,
	id,
	uniqueId,
	status,
	legType,
}: Pick<
	ViewLegsForDriverResponse,
	| "collectionDate"
	| "deliveryDate"
	| "customerName"
	| "id"
	| "uniqueId"
	| "status"
	| "legType"
>) => {
	const collectionDateString = useMemo(
		() => toDatetimeString(collectionDate),
		[collectionDate],
	);
	const deliveryDateString = useMemo(
		() => toDatetimeString(deliveryDate),
		[deliveryDate],
	);
	const customerNameString = customerName ?? "N/A";
	const statusString = legStatusTypeNames[status] ?? "N/A";
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<div>
				<div>
					<b>{legTypeNames[legType]}</b> {uniqueId ?? id}
				</div>
				<div>
					{collectionDateString} - {deliveryDateString}
				</div>
				<div>{customerNameString}</div>
			</div>
			<div>
				<div>{statusString}</div>
			</div>
		</div>
	);
};
