import "@progress/kendo-font-icons/dist/index.css";
import "@progress/kendo-theme-default/dist/all.css";
import "./styles.css";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { Provider } from "jotai";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffectOnce, useToggle } from "react-use";
import { store } from "../new/authAtoms";
import { axiosInstance } from "../new/helpers";
import { App } from "./App";

axiosInstance.defaults.headers.Application = "driver";

const localStoragePersister = createSyncStoragePersister({
	storage: window.localStorage,
});
const queryClient = new QueryClient();
persistQueryClient({ queryClient, persister: localStoragePersister });

const ReactQueryDevtoolsWrapper = () => {
	const [devtools, toggleDevtools] = useToggle(false);
	useEffectOnce(() => void Object.assign(window, { toggleDevtools }));
	if (!devtools) return null;
	return <ReactQueryDevtools buttonPosition="top-right" position="right" />;
};

const rootEl = document.getElementById("root");
if (!rootEl) throw new Error("Root element not found");
const root = createRoot(rootEl);

root.render(
	<QueryClientProvider client={queryClient}>
		<BrowserRouter>
			<Provider store={store}>
				<ToastContainer />
				<div style={{ touchAction: "pan-x pan-y" }}>
					<App />
				</div>
			</Provider>
			<ReactQueryDevtoolsWrapper />
		</BrowserRouter>
	</QueryClientProvider>,
);
