import { Button } from "@progress/kendo-react-buttons";
import { useCallback, useMemo, useState } from "react";
import type { ViewLegsForDriverResponse } from "../../../new/api/JobApi";
import {
	type LegStatusType,
	jobApi,
	legStatusTypeNames,
	toasted,
} from "../../../new/helpers";

type DriverLegActionButtonProps = {
	leg: ViewLegsForDriverResponse;
	refetch: () => void;
};
export const DriverLegActionButton = ({
	leg,
	refetch,
}: DriverLegActionButtonProps) => {
	const [loading, setLoading] = useState(false);
	const nextStatus = useMemo<LegStatusType | undefined>(() => {
		const currentStatusIndex = leg.availableStatuses.indexOf(leg.status);
		if (currentStatusIndex === -1) return undefined;
		return leg.availableStatuses[currentStatusIndex + 1];
	}, [leg.status, leg.availableStatuses]);
	const text = useMemo(() => {
		const fromText = legStatusTypeNames[leg.status] ?? "Unknown";
		const toText = (nextStatus && legStatusTypeNames[nextStatus]) ?? "Unknown";
		return `${fromText} -> ${toText}`;
	}, [nextStatus, leg.status]);
	const handleStatusForward = useCallback(async () => {
		if (nextStatus === undefined) return;
		setLoading(true);
		await toasted(
			jobApi.leg
				.legChangeStatusCreate({
					legId: leg.id,
					status: nextStatus,
				})
				.then(refetch)
				.finally(() => setLoading(false)),
			"Changing status",
		);
	}, [nextStatus, leg.id, refetch]);
	return (
		<Button
			icon={loading ? "loading" : "check"}
			themeColor="primary"
			disabled={loading}
			onClick={handleStatusForward}
		>
			{text}
		</Button>
	);
};
