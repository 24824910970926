import { Reveal } from "@progress/kendo-react-animation";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { jobApi } from "../../../new/helpers";
import { DriverHomeLayout } from "./DriverHomeLayout";
import { DriverLegBody } from "./DriverLegBody";
import { DriverLegItemTitle } from "./DriverLegItemTitle";

export const DriverHomePage = () => {
	const _legs = useQuery({
		queryKey: ["jobApi.leg.legForDriverList"],
		queryFn: () => jobApi.leg.legForDriverList().then((x) => x.data),
		initialData: [],
		refetchInterval: 3000,
	});
	const [expandedLegId, setExpandedLegId] = useState<number>();
	useEffect(() => {
		if (_legs.isFetchedAfterMount) return;
		if (_legs.data[0]?.id) {
			setExpandedLegId(_legs.data[0].id);
		}
	}, [_legs.data[0]?.id, _legs.isFetchedAfterMount]);
	const handleExpand = (legId: number) => {
		if (expandedLegId === legId) {
			return setExpandedLegId(undefined);
		}
		setExpandedLegId(legId);
	};
	return (
		<DriverHomeLayout>
			{!_legs.data.length && <h1>You don't have any work assigned.</h1>}
			{_legs.data.map((leg) => {
				const expanded = leg.id === expandedLegId;
				return (
					<div key={leg.id}>
						<ExpansionPanel
							expanded={expanded}
							onAction={() => handleExpand(leg.id)}
							title={<DriverLegItemTitle {...leg} />}
						>
							<Reveal>
								{expanded && (
									<ExpansionPanelContent>
										<DriverLegBody leg={leg} refetch={_legs.refetch} />
									</ExpansionPanelContent>
								)}
							</Reveal>
						</ExpansionPanel>
					</div>
				);
			})}
		</DriverHomeLayout>
	);
};
