import { Button } from "@progress/kendo-react-buttons";
import { plusIcon } from "@progress/kendo-svg-icons";
import type { ViewLegsForDriverResponse } from "../../../new/api/JobApi";
import { DFlex } from "../../../new/display/DFlex";
import {
	DocumentUsage,
	LegStatusType,
	jobApi,
	toasted,
} from "../../../new/helpers";
import { DriverLegActionButton } from "./DriverLegActionButton";
import { usePODDialog } from "./usePODDialog";

type DriverLegBodyProps = {
	leg: ViewLegsForDriverResponse;
	refetch: () => void;
};
export const DriverLegBody = ({ leg, refetch }: DriverLegBodyProps) => {
	const [togglePODDialog, podDialog] = usePODDialog({
		onSubmit: async (blob, goodsIds) => {
			const files = [
				new File([blob], `pod-leg-${leg.id}-${Date.now()}.png`, {
					type: blob.type,
				}),
			];
			await toasted(
				jobApi.attachment
					.attachmentCreate(
						{
							entityId: leg.id,
							goodsIds: goodsIds,
							usage: DocumentUsage.Leg,
							isPod: true,
						},
						{ files: files },
					)
					.then(() => {
						refetch();
						togglePODDialog(false);
					}),
				"Uploading POD",
			);
		},
		onCancel: () => togglePODDialog(false),
		leg,
	});
	return (
		<>
			{podDialog}
			{(leg.truckName || leg.trailerName) && (
				<>
					<b>Requested</b>
					<div>
						<div>
							<i>Truck:</i> <b>{leg.truckName ?? "N/A"}</b>
						</div>
						<div>
							<i>Trailer:</i> <b>{leg.trailerName ?? "N/A"}</b>
						</div>
					</div>
				</>
			)}
			{(leg.collectionLocationName || leg.deliveryLocationName) && (
				<>
					<b>Location</b>
					<div>
						<div>
							<i>Collection:</i> <b>{leg.collectionLocationName ?? "N/A"}</b>{" "}
							<b>{leg.collectionLocationAddress ?? "N/A"}</b>
						</div>
						<div>
							<i>Delivery:</i> <b>{leg.deliveryLocationName ?? "N/A"}</b>{" "}
							<b>{leg.deliveryLocationAddress ?? "N/A"}</b>
						</div>
					</div>
				</>
			)}
			{leg.containerNumber && (
				<>
					<b>Container</b>
					<div>
						<div>
							<i>Number:</i> <b>{leg.containerNumber}</b>
						</div>
						<div>
							<i>Pin:</i> <b>{leg.containerPin ?? "N/A"}</b>
						</div>
						<div>
							<i>Seal number:</i> <b>{leg.containerSealNumber ?? ""}</b>
						</div>
						<div>
							<i>Notes:</i> <b>{leg.notes ?? "N/A"}</b>
						</div>
					</div>
				</>
			)}
			{leg.goods.length > 0 && (
				<>
					<b>Goods</b>
					{leg.goods.map((good) => (
						<div key={good.id}>
							<div>
								<b>{good.quantity}x</b> <b>{good.name}</b>
							</div>
						</div>
					))}
				</>
			)}
			<hr />
			<DFlex column>
				{leg.status === LegStatusType.InEndPosition && (
					<Button svgIcon={plusIcon} onClick={() => togglePODDialog()}>
						Add POD ({leg.podCount})
					</Button>
				)}
				<DriverLegActionButton leg={leg} refetch={refetch} />
			</DFlex>
		</>
	);
};
