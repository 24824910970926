import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { cameraIcon, checkIcon, trashIcon } from "@progress/kendo-svg-icons";
import html2canvas from "html2canvas";
import { useEffect, useMemo, useRef, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { toast } from "react-toastify";
import type { ViewLegsForDriverResponse } from "../../../new/api/JobApi";
import { DFlex } from "../../../new/display/DFlex";
import { dataURLtoBlob } from "../../../new/helpers";
import { Now, useDialog } from "../../../new/helpersReact";

type Goods = ViewLegsForDriverResponse["goods"][0];
type PODProps = {
	leg: ViewLegsForDriverResponse;
	goods: Goods[];
	onSubmit: (pod: Blob, goodsIds: number[]) => unknown;
	onCancel?: () => unknown;
};
const POD = ({ leg, goods, onSubmit, onCancel }: PODProps) => {
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);
	const signaturePadRef = useRef<SignaturePad>(null);
	const printRef = useRef<HTMLDivElement>(null);
	const goodsIds = useMemo(() => goods.map((good) => good.id), [goods]);
	const handleSubmit = async () => {
		setLoading(true);
		try {
			const isEmpty = signaturePadRef.current?.isEmpty() ?? true;
			const element = printRef.current;
			if (name === "") return toast.error("Name is required");
			if (isEmpty) return toast.error("Signature is required");
			if (!element) return toast.error("The element is not ready");
			const canvas = await html2canvas(element);
			const data = canvas.toDataURL("image/png");
			await onSubmit(dataURLtoBlob(data), goodsIds);
		} finally {
			setLoading(false);
		}
	};
	const handleClear = () => {
		signaturePadRef.current?.clear();
		setName("");
	};
	return (
		<DFlex column>
			<div ref={printRef}>
				<DFlex center>
					<h1 style={{ marginTop: 0 }}>Proof of Delivery</h1>
				</DFlex>
				<DFlex column>
					<div>
						<i>Date:</i>
						<div>
							<b>
								<Now />
							</b>
						</div>
					</div>
					<div>
						<i>Goods:</i>
						<div>
							{goods.map((good) => (
								<div key={good.id}>
									{good.name} - {good.quantity}
								</div>
							))}
						</div>
					</div>
					<div>
						<i>Name:</i>
						<div>
							<Input
								placeholder="Name..."
								value={name}
								onChange={(e) => setName(e.value)}
							/>
						</div>
					</div>
					<div>
						<i>Signature:</i>
						<div
							style={{
								width: "100%",
								maxHeight: "200px",
								border: "1px solid #9d9dab",
								borderRadius: "4px",
							}}
						>
							<SignaturePad ref={signaturePadRef} />
						</div>
					</div>
				</DFlex>
			</div>
			<DFlex spaceBetween>
				<Button svgIcon={checkIcon} themeColor="error" onClick={onCancel}>
					Cancel
				</Button>
				<Button svgIcon={trashIcon} onClick={handleClear}>
					Clear
				</Button>
				<Button
					disabled={!name || loading}
					icon={loading ? "loading" : "save"}
					themeColor="primary"
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</DFlex>
			<Button
				disabled={loading}
				svgIcon={cameraIcon}
				themeColor="primary"
				style={{ width: "100%" }}
				onClick={() => {
					const fileUpload = document.querySelector<HTMLInputElement>(
						`#${leg.uniqueId ?? ""}`,
					);
					if (!fileUpload) return;
					fileUpload.value = "";
					fileUpload.click();
				}}
			>
				Or Submit a Photo POD
				<form onSubmit={() => false}>
					<input
						id={leg.uniqueId ?? undefined}
						onChange={async (e) => {
							const file = e.target.files?.[0];
							if (!file) return;
							setLoading(true);
							try {
								await onSubmit(new Blob([file], { type: file.type }), goodsIds);
							} finally {
								setLoading(false);
							}
						}}
						accept="image/*"
						type="file"
						hidden
					/>
				</form>
			</Button>
		</DFlex>
	);
};
type ChooseGoodsProps = {
	goods: Goods[];
	onSubmit: (goodsIds: Goods[]) => unknown;
};
const ChooseGoods = ({ goods, onSubmit }: ChooseGoodsProps) => {
	const [selectedGoods, setSelectedGoods] = useState<Goods[]>([]);
	const handleSubmit = () => {
		onSubmit(selectedGoods);
	};
	useEffect(() => {
		if (goods.length <= 1) onSubmit(goods);
	}, [onSubmit, goods]);
	return (
		<DFlex column>
			<h1>Choose Goods</h1>
			<DFlex column>
				{goods.map((good) => (
					<div key={good.id}>
						<input
							type="checkbox"
							id={`${good.id}`}
							checked={selectedGoods.includes(good)}
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedGoods([...selectedGoods, good]);
								} else {
									setSelectedGoods(selectedGoods.filter((g) => g !== good));
								}
							}}
						/>
						<label htmlFor={`${good.id}`}>{good.name}</label>
					</div>
				))}
			</DFlex>
			<Button
				disabled={!selectedGoods.length}
				themeColor="primary"
				onClick={handleSubmit}
			>
				Submit
			</Button>
		</DFlex>
	);
};

export const usePODDialog = (props: Omit<PODProps, "goods">) => {
	const [goods, setGoods] = useState<Goods[]>([]);
	const element = useMemo(
		() =>
			!goods.length ? (
				<ChooseGoods goods={props.leg.goods} onSubmit={setGoods} />
			) : (
				<POD {...props} goods={goods} />
			),
		[props, goods],
	);
	const [togglePODDialog, podDialog] = useDialog(element, "Proof of Delivery");

	return [
		(nextValue = true) => {
			setGoods([]);
			togglePODDialog(nextValue);
		},
		podDialog,
	] as const;
};
